import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from './components/header/header.component';
import { SubscriptionBannerComponent } from "./components/subscription-banner/subscription-banner.component";
import { LoaderComponent } from './core/loader/loader.component';
import { MessageComponent } from './core/message/message.component';
import { AuthService } from './services/auth.service';
import { DataService } from './services/data.service';
import { selectedTenantService } from './services/selectedTenant.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, MessageComponent,
    LoaderComponent, FooterComponent, SubscriptionBannerComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  showHeader: boolean = false;
  currentUrl: string = ''
  constructor(public authService: AuthService, private router: Router,
    public dataService: DataService, public selectedTenant:selectedTenantService) { }

  ngOnInit() {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url
        this.showHeader = this.authService.isLoggedIn && this.currentUrl != '/login' && this.currentUrl != '/register' && this.currentUrl != '/something-went-wrong' ? true : false
      }
    })
  }
}
