import { CommonModule, NgIf } from '@angular/common';
import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { EUserType } from '../../enums/user.enum';
import { IIsWabaVerifiedResp } from '../../interfaces/user.interface';
import { AuthService } from '../../services/auth.service';
import { CreditPointService } from '../../services/creditpoints.service';
import { DataService } from '../../services/data.service';
import { selectedTenantService } from '../../services/selectedTenant.service';
import { UserService } from '../../services/user.service';
import { SelectTenantComponent } from '../select-tenant/select-tenant.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule, ReactiveFormsModule, SelectTenantComponent, NgIf],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  isDropdown: boolean = false;

  EUserType = EUserType

  selTenWabaInfo: null | IIsWabaVerifiedResp = null
  creditPoints: number = 0

  @ViewChild('dropdownRef', { static: false }) dropdownRef?: ElementRef;
  constructor(public dataService: DataService,
    public userService: UserService,
    public authService: AuthService,
    private renderer: Renderer2,
    private router: Router,
    public selectedTenant: selectedTenantService,
    public creditPtService: CreditPointService) {
    this.renderer.listen('window', 'click', (event: Event) => {
      if (this.isDropdown && !this.dropdownRef?.nativeElement.contains(event.target)) {
        this.isDropdown = false;
      }
    })
  }

  ngOnInit() {
    this.selTenWabaInfo = this.selectedTenant.selectedTenWabaInfo
    this.watchSubTenant()
    if (this.authService.isUser && !this.authService.isSubTenant && this.userService.wabaInfo?.trial_days_left == 0) {
      this.watchCreditPoints()
      this.getCurrentCreditPoints()
      this.checkCreditAutoPayExists()
    }
  }

  onLogout(): void {
    this.userService.wabaInfo = null
    this.selectedTenant.resetSelectedTenant()
    this.authService.forceLogout()
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['login']);
    });
  }

  dropdownToggleEvent(event: Event) {
    event.preventDefault();
    this.isDropdown = !this.isDropdown;
  }

  watchSubTenant() {
    this.selectedTenant.selectedTenantWabaObs.subscribe(res => {
      this.selTenWabaInfo = res
    })
  }

  watchCreditPoints() {
    this.creditPtService.creditPointObs.subscribe(res => {
      this.creditPoints = res
    })
  }

  getCurrentCreditPoints() {
    this.creditPtService.getCurrentCreditPoints().subscribe(res => {
      if (res && res.response) {
        this.creditPtService.creditPoints = res.response.credit_points
      }
    })
  }

  checkCreditAutoPayExists() {
    if (this.authService.isTenant) {
      this.creditPtService.autoPayStatus(this.authService.tenantId).subscribe(res=>{
        if(res && res.response){
          this.creditPtService.creditPayAutoPayInfo = res.response
        }else{
          this.creditPtService.creditPayAutoPayInfo =  null
        }
      })
    }

  }

}
