import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { selectedTenantService } from '../services/selectedTenant.service';
import { UserService } from '../services/user.service';

export const wabaNotVerifiedGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService)
  const userService = inject(UserService)
  const selectedTenant = inject(selectedTenantService)
  const router = inject(Router)

  if (!authService.isUser || (authService.isTenant && userService.wabaInfo?.waba_verified) || (authService.isSuperTenant && selectedTenant.selectedTenWabaInfo?.waba_verified) 
    || authService.isSubTenant) {
    router.navigate(['404']);
    return false;
  } else {
    return true;
  }
};
