@if(message){
<div class="banner">
    <div class="flex align-items-center">
        <p>{{message}}</p>

        @if(trialPeriod){
        <app-th-with-info
            tooltipText="During trial period you will have limited access to our features."></app-th-with-info>
        }@else {
        @if(authService.isTenant && userService.wabaInfo?.is_in_trial_period){
        &nbsp; <a [routerLink]="['subscribe']"> Subscribe</a>
        }@else if(authService.isSuperTenant && !selectedTenant.selectedTenantInfo && userService.wabaInfo?.is_in_trial_period){
        &nbsp; <a [routerLink]="['manage-company/select-package']"> Purchase a Package</a>
        }
        }

    </div>
</div>
}