import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

export const creditPointGuard: CanActivateFn = (route, state) => {
  const auth = inject(AuthService)
  const userService = inject(UserService)
  const router = inject(Router)

  if ((auth.isTenant || auth.isSuperTenant) && userService.wabaInfo?.trial_days_left == 0 ) {
    return true;
  }
  router.navigate(['404']);
  return false;
};