import { Routes } from '@angular/router';
import { LoginPasswordLessComponent } from './components/login-password-less/login-password-less.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AllUserGuard } from './guards/all-user.guard';
import { allowMessagingGuard } from './guards/allow-messaging.guard';
import { AuthGuard } from './guards/auth.guard';
import { notSubtenantGuard } from './guards/not-sub-tenant.guard';
import { profileGuard } from './guards/profile.guard';
import { subscribeGuard } from './guards/subscribe.guard';
import { SuperAdminAuthGuard } from './guards/super-admin.guard';
import { superTenantGuard } from './guards/super-tenant.guard';
import { transactionGuard } from './guards/transaction.guard';
import { WabaGuard } from './guards/waba.guard';
import { SomethingWentWrongComponent } from './components/something-went-wrong/something-went-wrong.component';
import { tenantGuard } from './guards/tenant.guard';
import { wabaNotVerifiedGuard } from './guards/waba-not-verified.guard';
import { creditPointGuard } from './guards/credit-point.guard';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'login'
    },
    {
        path: 'home',
        redirectTo: 'login'
    },
    {
        path: 'login',
        component: LoginPasswordLessComponent
    },
    {
        path: 'register',
        loadComponent: () => import('./components/register/register.component').then(comp => comp.RegisterComponent)
    },
    {
        path: "sender",
        children: [
            {
                path: "unverified-waba",
                loadComponent: () => import('./components/waba-unverified/waba-unverified.component').then(comp => comp.WabaUnverifiedComponent),
                canActivate: [AuthGuard, AllUserGuard]
            },
            {
                path: "waba-verification-with-facebook",
                loadComponent: () => import('./components/verify-waba-fb/verify-waba-fb.component').then(comp => comp.VerifyWabaFbComponent),
                canActivate: [AuthGuard, wabaNotVerifiedGuard],
            },
            {
                path: "sender-no-list",
                loadComponent: () => import('./components/sender-no-list/sender-no-list.component').then(comp => comp.SenderNoListComponent),
                canActivate: [AuthGuard, AllUserGuard]
            }, {
                path: '',
                redirectTo: 'waba-verification-with-facebook'
            },
        ]
    },
    {
        path: 'admin',
        children: [{
            path: 'manage-company',
            loadComponent: () => import('./modules/admin/companies/manage-company/manage-company.component').then(comp => comp.ManageCompanyComponent),
            canActivate: [AuthGuard, SuperAdminAuthGuard],

        }, {
            path: "manage-company/update-company/:companyId",
            loadComponent: () => import('./modules/admin/companies/add-company/add-company.component').then(comp => comp.AddCompanyComponent),
            canActivate: [AuthGuard, SuperAdminAuthGuard],
        }, {
            path: "manage-packages",
            loadComponent: () => import('./modules/admin/manage-packages/manage-packages.component').then(comp => comp.ManagePackagesComponent),
            canActivate: [AuthGuard, SuperAdminAuthGuard],
        }]
    },
    {
        path: "my-profile",
        loadComponent: () => import('./components/my-profile/my-profile.component').then(comp => comp.MyProfileComponent),
        canActivate: [AuthGuard, profileGuard],

    }, {
        path: 'manage-company',
        children: [
            {
                path: '',
                loadComponent: () => import('./modules/company-admin/sub-companies/manage-sub-company/manage-sub-company.component').then(comp => comp.ManageSubCompanyComponent),
                canActivate: [AuthGuard, superTenantGuard],
            },
            {
                path: "add-company",
                loadComponent: () => import('./modules/company-admin/sub-companies/add-sub-company/add-sub-company.component').then(comp => comp.AddSubCompanyComponent),
                canActivate: [AuthGuard, superTenantGuard],
            },
            {
                path: "update-company/:subCompanyId",
                loadComponent: () => import('./modules/company-admin/sub-companies/add-sub-company/add-sub-company.component').then(comp => comp.AddSubCompanyComponent),
                canActivate: [AuthGuard, superTenantGuard],
            },
            // {
            //     path: 'package/transactions',
            //     loadComponent: () => import('./components/subscription-transactions/subscription-transactions.component').then(comp => comp.SubscriptionTransactionsComponent),
            //      canActivate: [AuthGuard, superTenantGuard],
            // },
            {
                path: 'select-package',
                children: [{
                    path: '',
                    loadComponent: () => import('./components/select-packages/select-packages.component').then(comp => comp.SelectPackagesComponent),
                    canActivate: [AuthGuard, superTenantGuard]
                },
                ]
            }
        ]

    },
    {
        path: 'one-time-payment/:paymentFlow',
        loadComponent: () => import('./modules/company-admin/package-payment/package-payment.component').then(comp => comp.PackagePaymentComponent),
        canActivate: [AuthGuard, transactionGuard]
    },
    {
        path: "manage-leads",
        children: [
            {
                path: '',
                loadComponent: () => import('./modules/company-admin/userlist/manage-userlist/manage-userlist.component').then(comp => comp.ManageUserlistComponent),
                canActivate: [AuthGuard, AllUserGuard, subscribeGuard],
            }, {
                path: "upload-leads-list",
                loadComponent: () => import('./modules/company-admin/userlist/add-userlist/add-userlist.component').then(comp => comp.AddUserlistComponent),
                canActivate: [AuthGuard, notSubtenantGuard, subscribeGuard],

            }, {
                path: "view-leads/:userlistId",
                loadComponent: () => import('./modules/company-admin/userlist/view-users/view-users.component').then(comp => comp.ViewUsersComponent),
                canActivate: [AuthGuard, AllUserGuard, subscribeGuard],

            }
        ]

    }, {
        path: "manage-campaigns",
        children: [
            {
                path: '',
                loadComponent: () => import('./modules/company-admin/campaigns/manage-compaigns/manage-compaigns.component').then(comp => comp.ManageCompaignsComponent),
                canActivate: [AuthGuard, AllUserGuard, subscribeGuard, WabaGuard, allowMessagingGuard],
            }, {
                path: "add-campaign",
                loadComponent: () => import('./modules/company-admin/campaigns/add-campaign/add-campaign.component').then(comp => comp.AddCampaignComponent),
                canActivate: [AuthGuard, notSubtenantGuard, subscribeGuard, WabaGuard, allowMessagingGuard]

            }, {
                path: "update-campaign/:campaignId",
                loadComponent: () => import('./modules/company-admin/campaigns/add-campaign/add-campaign.component').then(comp => comp.AddCampaignComponent),
                canActivate: [AuthGuard, notSubtenantGuard, subscribeGuard, WabaGuard, allowMessagingGuard]

            }, {
                path: "message-status/:campaignId",
                loadComponent: () => import('./modules/company-admin/campaigns/message-status/message-status.component').then(comp => comp.MessageStatusComponent),
                canActivate: [AuthGuard, AllUserGuard, subscribeGuard, WabaGuard, allowMessagingGuard],

            }
        ]
    }, {
        path: "manage-templates",
        children: [
            {
                path: '',
                loadComponent: () => import('./modules/company-admin/templates/manage-template/manage-template.component').then(comp => comp.ManageTemplateComponent),
                canActivate: [AuthGuard, AllUserGuard, subscribeGuard, WabaGuard, allowMessagingGuard],
            },
            {
                path: "create-templates",
                loadComponent: () => import('./modules/company-admin/templates/create-template/create-template.component').then(comp => comp.CreateTemplateComponent),
                canActivate: [AuthGuard, notSubtenantGuard, subscribeGuard, WabaGuard, allowMessagingGuard],
            },
        ]

    },
    {
        path: "subscribe",
        children: [
            {
                path: '',
                loadComponent: () => import('./components/subscribe-page/subscribe-page.component').then(comp => comp.SubscribePageComponent),
                canActivate: [AuthGuard, tenantGuard]
            },
            {
                path: "make-payment",
                loadComponent: () => import('./components/subscription-payment/subscription-payment.component').then(comp => comp.SubscriptionPaymentComponent),
                canActivate: [AuthGuard, tenantGuard]
            },
        ]
    },
    {
        path: "subscriptions",
        loadComponent: () => import('./components/subscriptions/subscriptions.component').then(comp => comp.SubscriptionsComponent),
        canActivate: [AuthGuard, transactionGuard]
    },
    {
        path: 'credit-points',
        children: [
            {
                path: '',
                loadComponent: () => import('./components/credit-points/credit-points.component').then(comp => comp.CreditPointsComponent),
                canActivate: [AuthGuard, creditPointGuard]
            },
            {
                path: "make-payment/autopay",
                loadComponent: () => import('./components/credit-autopay/credit-autopay.component').then(comp => comp.CreditAutopayComponent),
                canActivate: [AuthGuard, creditPointGuard]
            },
            {
                path: "utilization",
                loadComponent: () => import('./components/creditpoint-utilization/creditpoint-utilization.component').then(comp => comp.CreditpointUtilizationComponent),
                canActivate: [AuthGuard, creditPointGuard],
            }
        ]
    },
    {
        path: "transactions",
        loadComponent: () => import('./components/transactions/transactions.component').then(comp => comp.TransactionsComponent),
        canActivate: [AuthGuard, transactionGuard],

    },
    {
        path: "privacy-policy",
        loadComponent: () => import('./components/privacy-policy/privacy-policy.component').then(comp => comp.PrivacyPolicyComponent)
    }, {
        path: "refund-policy",
        loadComponent: () => import('./components/refund-policy/refund-policy.component').then(comp => comp.RefundPolicyComponent)
    }, {
        path: "terms-conditions",
        loadComponent: () => import('./components/terms-conditions/terms-conditions.component').then(comp => comp.TermsConditionsComponent)
    },
    { path: 'something-went-wrong', component: SomethingWentWrongComponent },
    { path: '404', component: PageNotFoundComponent },
    { path: '**', redirectTo: '404' },

];
