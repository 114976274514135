import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptgroup, MatOption } from '@angular/material/select';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { IDropdown } from '../../core/interface/master.interface';
import { selectedTenantService } from '../../services/selectedTenant.service';
import { UserService } from '../../services/user.service';
import { UtilityService } from '../../services/utils.service';
import { DataService } from '../../services/data.service';
@Component({
  selector: 'app-select-subtenant',
  standalone: true,
  imports: [RouterModule, MatAutocompleteModule, MatFormField, MatInputModule, MatLabel, MatOption, ReactiveFormsModule, MatOptgroup],
  templateUrl: './select-tenant.component.html',
  styleUrl: './select-tenant.component.scss',
  providers: [{
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      subscriptSizing: 'dynamic'
    }
  }]
})
export class SelectTenantComponent implements OnInit {
  @ViewChild('selectTenant') selectTenantAC!: MatAutocomplete
  tenantSelectControl = new FormControl({ value: '', label: '' })
  subTenantList: IDropdown[] = []
  initOption: '' | 'start' | 'done' = ''

  constructor(public utilityService: UtilityService,
    public selectedTenantService: selectedTenantService,
    private route: ActivatedRoute,
    public router: Router,
    private userService: UserService,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.reloadTenantList()
    this.getAllSubtenants()
    this.onSubtenantValueChanges()
  }
  ngAfterViewChecked() {
    if (this.initOption == 'start' && this.selectTenantAC.options.length > 0) {
      this.UnSelectOptionInProgram(true)
      this.initOption = 'done'
    }
  }

  getAllSubtenants(): void {
    this.selectedTenantService.getSubCompanies(this.dataService.getLocalStorageItem('tenant_id')).subscribe((res) => {
      if (res.response) {
        let activeSubTenants = this.utilityService.getActiveItemsOnly(res.response.sub_tenants , 'status')
        this.subTenantList = this.utilityService.convertToDropdownOptions(activeSubTenants, 'tenant_id', 'tenant_name')
        if (!this.initOption && this.selectedTenantService.selectedTenantInfo) {
          this.tenantSelectControl.setValue(this.selectedTenantService.selectedTenantInfo ,{emitEvent:false})
          this.initOption = 'start'
        }
      }
    })
  }
  onSubtenantValueChanges() {
    this.tenantSelectControl.valueChanges.subscribe((res: any) => {
      if (res) {
        this.selectedTenantService.selectedTenantInfo = res
        this.isTenantWabaVerified(res)
      }
    })
  }

  isTenantWabaVerified(tenant: IDropdown) {
    this.userService.getWabaDetails(tenant.value).subscribe(res => {
      if (res && res.response) {
        this.selectedTenantService.selectedTenWabaInfo = res.response
        this.dataService.setLocalStorageItem('selectedTenant', JSON.stringify(tenant))
        const currentUrl = this.router.url;
        if (currentUrl.includes('manage-company') || currentUrl == '/404' ||currentUrl.includes('/credit-points')) {
          this.router.navigate(['/manage-campaigns']);
        } else {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
          });
        }
      }
    })
  }

  reloadTenantList() {
    this.route.queryParams.subscribe(queryParam => {
      if (queryParam['reloadTenants']) {
        this.getAllSubtenants()
      }
    })
  }

  goBackToAdmin() {

    this.tenantSelectControl.reset({ value: '', label: '' }, { emitEvent: false })
    this.UnSelectOptionInProgram(false)
    this.selectedTenantService.resetSelectedTenant()
    this.dataService.deleteLocalSessionItem('selectedTenant')
    this.userService.reloadWabaInfo()
    this.utilityService.onNavigate('manage-company')
  }

  UnSelectOptionInProgram(selectOption: boolean) {
    let selectedOption = this.selectTenantAC.options.find((option: MatOption) =>
      this.tenantSelectControl.value ? option.value.value == this.tenantSelectControl.value.value : false
    )
    if(selectOption){
      selectedOption?.select()
    }else{
      selectedOption?.deselect()
    }
  }

}
